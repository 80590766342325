import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);
const router = new VueRouter({
    routes: [{
            path: '/',
            name: 'index',
            redirect: '/addreport',
            component: () => import('@/components/layout.vue'),
            children: [{
                    path: 'addreport',
                    name: 'addReport',
                    component: () => import('@/views/addReport/index.vue'),
                }, {
                    path: '/sendrecord',
                    name: 'sendRecord',
                    component: () => import('@/views/sendRecord/index.vue'),
                },
                {
                    path: '/help',
                    name: 'Help',
                    component: () => import('@/views/Help/index.vue'),
                }, {
                    path: '/my',
                    name: 'My',
                    component: () => import('@/views/my/my.vue'),
                }, {
                    path: '/students',
                    name: 'Students',
                    component: () => import('@/views/my/students.vue'),
                }, {
                    path: '/drafts',
                    name: 'Drafts',
                    component: () => import('@/views/my/drafts.vue'),
                },
            ]
        },

        {
            path: '/login',
            name: 'Login',
            component: () => import('@/views/Login'),
        },
        {
            path: '/404',
            name: 'Page404',
            component: () => import('@/views/Error'),
        }
    ]
})
router.beforeEach((to, from, next) => {
    // next();

    if (to.path !== '/login') {
        if (window.localStorage.getItem("Authorization")) {
            next();
        } else {
            next("login");
        }
    } else {
        next();
    }

})
export default router